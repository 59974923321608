




















import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import { Freq, MeetingRepeatabilityType } from '@tada-team/tdproto-ts'

@Component
export default class RepeatabilityType extends Vue {
  @VModel({
    required: true,
    type: Object,
  }) freq!: Freq

  @Prop({
    type: Boolean,
  }) isEditDialog?: boolean

  get items (): {type: MeetingRepeatabilityType, title: string}[] {
    return [{
      type: 'MEETING_PRESENCE_STATUS_WEEKLY',
      title: this.$t('meetings.repeatability.byWeek').toString(),
    }, {
      type: 'MEETING_PRESENCE_STATUS_DAILY',
      title: this.$t('meetings.repeatability.byDay').toString(),
    }]
  }

  private changeRepeatabilityType (status: MeetingRepeatabilityType) {
    this.sendGoal(status)
    this.freq.repeatabilityType = status
    this.$emit('updateFrequencyDescription')
  }

  private sendGoal (status: MeetingRepeatabilityType) {
    window.goal('Calendar', {
      Calendar: status === 'MEETING_PRESENCE_STATUS_DAILY'
        ? this.isEditDialog
          ? 'Окно редактирования — включить «По дням»'
          : 'Окно создания — включить «По дням»'
        : this.isEditDialog
          ? 'Окно редактирования — включить «По неделям»'
          : 'Окно создания — включить «По неделям»',
    })
  }
}
